import { GTMDefaults, GTMEvents } from '../enums'
import { IOrderModel } from '~/models/components/Order'
/* eslint-disable camelcase */
export interface IActionField {
  store_currency: string
  id: string
  purchase_currency: string
  order_type: string
  order_delivery_method: string
  revenue_stored: string
  revenue: string
  coupon?: string
  order_payment_type?: string
  order_service_fee_stored: string
  order_service_fee_default: string
}
export interface IPurchaseProduct {
  id: string
  name: string
  price: string
  quantity: string
  product_brand: string | null
  product_theme: string | null
  category: string
  brand: string
  product_price_stored: string
}

export interface IEnhancedConversionData {
  email: string
  phone: string
}
/* eslint-enable camelcase */
export interface IPurchaseEcommerce {
  currencyCode: string
  purchase: {
    actionField: IActionField
    products: IPurchaseProduct[]
  }
}

export interface IPurchase {
  event: string
  ecommerce: IPurchaseEcommerce
}

export interface IPurchaseParams {
  order: IOrderModel
  currency: string
}

async function sha256(message: string) {
  try {
    const msgBuffer = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    return hashHex
  } catch (error) {
    return ''
  }
}

export class Purchase {
  private ecommerce: IPurchaseEcommerce
  constructor({ order, currency }: IPurchaseParams) {
    const currencyCode = currency
    const actionField = Purchase.formatActionField({ order, currency })
    const products = [Purchase.formatProducts(order)]
    this.ecommerce = {
      currencyCode,
      purchase: {
        actionField,
        products,
      },
    }
  }

  static formatActionField({ order, currency }: IPurchaseParams): IActionField {
    const { id, totalPrice, serviceFee, paymentMethod, isRTR } = order
    const deliveryMethod = isRTR ? 'rtr' : 'pin'
    return {
      store_currency: GTMDefaults.DEFAULT_CURRENCY,
      id: id.toString(),
      purchase_currency: currency,
      order_type: 'standard',
      order_delivery_method: deliveryMethod,
      revenue_stored: totalPrice,
      revenue: totalPrice,
      order_payment_type: paymentMethod,
      order_service_fee_stored: serviceFee,
      order_service_fee_default: serviceFee,
      ...(order.voucher ? { coupon: order.voucher } : {}),
    }
  }

  static formatProducts({
    products,
    price,
    quantity,
  }: IOrderModel): IPurchaseProduct {
    const [cmsProduct] = products
    const {
      productId,
      title,
      brandTitle = '',
      brandCategory = '',
      baseCategory = '',
      nominationType,
    } = cmsProduct

    const brandType =
      nominationType === 'brand'
        ? { product_brand: brandTitle, product_theme: null }
        : { product_brand: null, product_theme: brandTitle }

    const category = baseCategory
      ? `${baseCategory}/${brandCategory}`
      : brandCategory

    return {
      ...brandType,
      id: productId,
      name: title,
      product_price_stored: price,
      brand: brandTitle,
      price,
      category,
      quantity,
    }
  }

  static async formatEnhancedConversionData(
    order
  ): Promise<IEnhancedConversionData> {
    return {
      email: await sha256(order.email || ''),
      phone: await sha256(order.phone || ''),
    }
  }

  toJSON(): IPurchase {
    return {
      event: GTMEvents.PURCHASE,
      ecommerce: this.ecommerce,
    }
  }
}
