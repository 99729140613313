import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F309138af0e2e4de580d3335ec59c1da9@o31660.ingest.sentry.io\u002F1827956",
    environment:"production",
    release:"e3a3a5d2003758fd56816cc44b8d8e7b30c8b115",
    ignoreUrls:[new RegExp("localhost", "")],
    sanitizeKeys:[],
    ignoreErrors:[new RegExp("ChunkLoadError", "")],
    beforeSend:(event, hint) => {
  // We define variables inside the function instead of outside,
  // because webpack/nuxt/sentry won't serialize non-included variables properly.
  // Versions at time of writing: Nuxt - 2.14.1, @nuxtjs/sentry - 5.1.6, Webpack - 4.46.0.

  const tagContentErrors = (event, hint) => {
    if (!event) return null;
    if (hint.originalException.type !== 'RecommerceContentError') return event;
    return {
      ...event,
      tags: { ...event.tags, RecommerceContentError: true }
    };
  };

  const mutations = [tagContentErrors];

  const mutatedEvent = mutations.reduce(
    (event, mutationFn) => mutationFn(event, hint),
    event
  );
  return mutatedEvent;
}
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
