import { NuxtAppOptions, Plugin } from '@nuxt/types'
import { Store } from 'vuex'
import {
  COUPON_STATUS,
  GTMEvents,
  CHECKOUT_ERROR_SCENARIOS,
  AUTH_EVENT_METHODS,
} from './enums'
import { PageViewModel } from './models/PageView'
import { PageErrorModel } from './models/PageError'
import { AddToCartModel } from './models/AddToCart'
import { CheckoutProgressModel } from './models/CheckoutProgress'
import { CheckoutLoadModel } from './models/CheckoutLoad'
import { Purchase } from './models/Purchase'
import { ProductImpressions } from './models/ProductImpressions'
import { GmpProductImpressions } from './models/GmpProductImpressions'
const {
  APPLY_COUPON,
  BACK_TO_TOP,
  CATEGORY_COMING_SOON,
  CHANGE_NUMBER,
  CHANGE_OUT_OF_STOCK_PRODUCT,
  CHECKOUT_FPC,
  CLICK_BRAND,
  CLICK_COUPON,
  CLICK_PREFERENCES,
  COUNTRY_SELECTION,
  FORM_SUBMIT,
  GEOLOCK_PRODUCT,
  CHANGE_GEOLOCATION_PRODUCT,
  NUMBER_VALIDATION_INSERT,
  ORDER_ID,
  OUT_OF_STOCK_PRODUCT,
  PAYMENT_ERROR,
  QUANTITY_SELECTION,
  REMOVE_COUPON,
  SWITCH_SUBCATEGORY,
  WHY_SERVICE_FEE,
  RESEND_VERIFICATION_CODE,
  SEARCH,
  SHOW_MORE_PAYMENT_METHODS,
  SHOW_LESS_PAYMENT_METHODS,
  VIEW_SEARCH_RESULTS,
  REFUND,
  CHECKOUT_CREDITCARD_STARTPAYMENT,
  CHECKOUT_AVS_DETAILS_LOADED,
  CHECKOUT_MOBILEVERIFICATION_INPUT_ERROR,
  CHECKOUT_MOBILEVERIFICATION_RESEND_CODE,
  CHECKOUT_MOBILEVERIFICATION_VERIFY,
  CHECKOUT_AVS_DETAILS_STARTPAYMENT,
  CHECKOUT_CREDITCARD_DETAILS_LOADED,
  CHECKOUT_MOBILEVERIFICATION_LOADED,
  CHECKOUT_PSC_DETAILS_CONTINUE,
  CHECKOUT_PSC_DETAILS_LOADED,
  CHECKOUT_PSC_DETAILS_CHANGE,
  RECHARGE_CONTACT_BUTTON,
  SMS_ID,
  SHOW_REORDER_BANNER,
  SHOW_ALL_ORDERS,
  REORDER,
  CLICK_RAF_PROMOTIONAL_TERMS,
  CLICK_JOIN_BY_RAF,
  SHOW_REORDER_BUTTON,
  REORDER_CHANGE_AMOUNT,
  SHOW_WEB_TO_APP_BANNER,
  WEB_TO_APP_DOWNLOAD_APP,
  WEB_TO_APP_CONTINUE_NAVIGATING,
} = GTMEvents
declare module '@nuxt/types' {
  interface Context {
    $gtm: NuxtAppOptions
  }
}

export type NumberModificationResponse = 'success' | 'check_number'
export type NumberModificationType = 'validate' | 'change'

class Analitycs {
  public $gtm: NuxtAppOptions
  public router: NuxtAppOptions
  public store: Store<any> | undefined
  public route: NuxtAppOptions

  constructor({ $gtm, route, store }) {
    this.$gtm = $gtm
    this.route = route
    this.store = store
  }

  trackPageView(to, store, pageType, pageViewAttrs, additionalData = {}): void {
    Analitycs.resetDataLayer()

    const orderErrorRouteReg = /^order-error.*/i
    const isOrderErrorPage = orderErrorRouteReg.test(to.name)

    if (isOrderErrorPage) return

    const pageViewData = new PageViewModel({
      url: to,
      store,
      pageType,
      pageViewAttrs,
    }).toJSON()

    this.$gtm.push({ ...pageViewData, ...additionalData })
  }

  trackAddToCart(product, { abv }, addList = false, listValue = null): void {
    const addToCartData = new AddToCartModel({
      product,
      currency: abv,
      addList,
      listValue,
    }).toJSON()

    this.$gtm.push({ ...addToCartData })
  }

  trackCheckoutLoad(product, step): void {
    const checkoutData = new CheckoutLoadModel(product, step).toJSON()
    this.$gtm.push({ ...checkoutData })
  }

  trackCheckoutProgress(step, option): void {
    const checkoutProgressData = new CheckoutProgressModel(
      step,
      option
    ).toJSON()

    this.$gtm.push({ ...checkoutProgressData })
  }

  trackPrimerCheckoutSubmitPayment(event: GTMEvents, data): void {
    this.$gtm.push({
      event,
      payment_id: data.id,
      order_id: data.orderId,
      status: data.status,
    })
  }

  trackSpecificEvent(event: GTMEvents): void {
    this.$gtm.push({
      event,
    })
  }

  trackFormSubmition(name: string): void {
    this.$gtm.push({
      event: FORM_SUBMIT,
      form_name: name,
    })
  }

  trackBackToTop(): void {
    this.$gtm.push({
      event: BACK_TO_TOP,
    })
  }

  trackQuantityChange(quantity: number, name: string): void {
    this.$gtm.push({
      event: QUANTITY_SELECTION,
      quantity,
      product_quantity_changed: name,
    })
  }

  verificationSmsReference(reference: string): void {
    this.$gtm.push({
      event: SMS_ID,
      sms_id: reference,
    })
  }

  userAuthSuccess(userId: string): void {
    if (this.store?.getters['context/accountAnalyticsDisabled']) return
    this.$gtm.push({
      event: GTMEvents.AUTHENTICATION_SUCCESS,
      user_id: userId,
    })
  }

  userAuthentication({
    event,
    method,
  }: {
    event: GTMEvents
    method: AUTH_EVENT_METHODS
  }): void {
    if (this.store?.getters['context/accountAnalyticsDisabled']) return
    this.$gtm.push({
      event,
      method,
    })
  }

  userAuthErrorEvent(
    event: string,
    type: string,
    location: string,
    errorMessage?: string
  ): void {
    if (this.store?.getters['context/accountAnalyticsDisabled']) return
    this.$gtm.push({
      event,
      error_type: type,
      error_location: location,
      error_msg: errorMessage,
    })
  }

  updatedPassword(): void {
    if (this.store?.getters['context/accountAnalyticsDisabled']) return
    this.$gtm.push({
      event: GTMEvents.UPDATE_PASSWORD,
    })
  }

  passwordReset(): void {
    if (this.store?.getters['context/accountAnalyticsDisabled']) return
    this.$gtm.push({
      event: GTMEvents.PASSWORD_RESET,
    })
  }

  emailVerified(): void {
    if (this.store?.getters['context/accountAnalyticsDisabled']) return
    this.$gtm.push({
      event: GTMEvents.VERIFIED_EMAIL,
    })
  }

  accountSignOut(): void {
    if (this.store?.getters['context/accountAnalyticsDisabled']) return
    this.$gtm.push({
      event: GTMEvents.SIGN_OUT,
    })
  }

  trackPageError(to, store, error): void {
    Analitycs.resetDataLayer()
    const pageErrorData = new PageErrorModel({
      url: to,
      store,
      error,
    }).toJSON()

    this.$gtm.push({ ...pageErrorData })
  }

  trackProductTabChange(tab): void {
    this.$gtm.push({
      event: SWITCH_SUBCATEGORY,
      subcategory: tab,
    })
  }

  trackClickServiceFee(): void {
    this.$gtm.push({
      event: WHY_SERVICE_FEE,
    })
  }

  trackChangePhoneNumber(): void {
    this.$gtm.push({
      event: CHANGE_NUMBER,
    })
  }

  trackNumberValidationInsert(): void {
    this.$gtm.push({
      event: NUMBER_VALIDATION_INSERT,
    })
  }

  trackNumberValidationResponse(event, response, prefix, nsnLength): void {
    this.$gtm.push({
      event,
      number_validation_response: response, //eslint-disable-line
      country_prefix: prefix,
      nsn_length: nsnLength,
    })
  }

  trackNumberValidationExit(event, step): void {
    this.$gtm.push({
      event,
      NV_step: step, //eslint-disable-line
    })
  }

  clickedRechargeContactButton(): void {
    this.$gtm.push({
      event: RECHARGE_CONTACT_BUTTON,
    })
  }

  selectedFieldText(eventName: string): void {
    this.$gtm.push({
      event: eventName,
    })
  }

  clickedCopyButton(eventName: string): void {
    this.$gtm.push({
      event: eventName,
    })
  }

  static resetDataLayer(): void {
    const { dataLayer, google_tag_manager: googleTagManager } = window

    if (!dataLayer || !googleTagManager) return

    Object.keys(googleTagManager).forEach(Analitycs.resetGtmKey)
  }

  private static resetGtmKey(gtmKey: string): void {
    const { google_tag_manager: googleTagManager } = window
    const gtmContainerReg = /^GTM-/i
    const gtm = googleTagManager[gtmKey]
    const isValidGtmKey = gtmContainerReg.test(gtmKey)
    const gtmReset = gtm.dataLayer && gtm.dataLayer.reset
    if (!isValidGtmKey || !gtmReset) return

    gtmReset()
  }

  trackPaymentError(): void {
    const {
      params: { errorType, paymentMethod },
    } = this.route

    this.$gtm.push({
      event: PAYMENT_ERROR,
      payment_error_code: errorType,
      payment_method: paymentMethod,
    })
  }

  trackCheckoutEvent(eventName: string): void {
    const {
      params: { errorType, paymentMethod },
    } = this.route

    if (errorType) {
      this.$gtm.push({
        event: eventName,
        payment_scenario: CHECKOUT_ERROR_SCENARIOS[errorType.toUpperCase()],
        payment_method: paymentMethod,
      })
    }
  }

  trackRefundRequest({
    storeCurrency,
    defaultCurrency,
    ecommerce,
  }: {
    storeCurrency: string
    defaultCurrency: string
    transactionId: string
    ecommerce: {
      refund: {
        actionField: {
          id: string
        }
        products: Array<{ id: string; quantity: string }>
      }
    }
  }): void {
    this.$gtm.push({
      event: REFUND,
      store_currency: storeCurrency,
      default_currency: defaultCurrency,
      ecommerce,
    })
  }

  trackOrderId(status: string): void {
    const {
      params: { id },
    } = this.route

    this.$gtm.push({
      event: ORDER_ID,
      order_status: status,
      order_id: id,
    })
  }

  trackOrderIdForPrimer(id): void {
    this.$gtm.push({
      event: ORDER_ID,
      order_id: id,
    })
  }

  trackFraudPrevention(fcp = 'null'): void {
    this.$gtm.push({
      event: CHECKOUT_FPC,
      checkout_fpc: fcp,
    })
  }

  async trackPurchaseRehydrate(order, { abv }): Promise<void> {
    const purchase = new Purchase({ order, currency: abv }).toJSON()
    const enhancedConversionData = await Purchase.formatEnhancedConversionData(
      order
    )

    const purchaseWithEnhancedConversionData = {
      enhanced_conversion_data: enhancedConversionData,
      ...purchase,
    }

    this.$gtm.push({ ...purchaseWithEnhancedConversionData })
  }

  trackProductImpressions(products, { abv }): void {
    const productImpressions = new ProductImpressions({
      products,
      currencyCode: abv,
    }).toJSON()

    this.$gtm.push({ ...productImpressions })
  }

  trackGmpProductImpressions(products, { abv }, listValue = null): void {
    const productImpressions = new GmpProductImpressions({
      products,
      currencyCode: abv,
      listValue,
    }).toJSON()

    this.$gtm.push({ ...productImpressions })
  }

  trackLocaleChange(locale: string): void {
    const [language, country] = locale.split('-')
    this.$gtm.push({
      event: COUNTRY_SELECTION,
      store_country: country,
      store_language: language,
    })
  }

  trackApplyCoupon(couponStatus: COUPON_STATUS) {
    this.$gtm.push({
      event: APPLY_COUPON,
      coupon_status: couponStatus,
    })
  }

  trackBrandClick(brand, sectionTitle) {
    this.$gtm.push({
      event: CLICK_BRAND,
      selected_category: sectionTitle,
      selected_brand: brand.slug,
    })
  }

  trackClickCoupon() {
    this.$gtm.push({
      event: CLICK_COUPON,
    })
  }

  trackRemoveCoupon() {
    this.$gtm.push({
      event: REMOVE_COUPON,
    })
  }

  trackGeolockProduct() {
    this.$gtm.push({
      event: GEOLOCK_PRODUCT,
    })
  }

  trackChangeGeolocationProduct() {
    this.$gtm.push({
      event: CHANGE_GEOLOCATION_PRODUCT,
    })
  }

  trackChangeOutOfStockProduct() {
    this.$gtm.push({
      event: CHANGE_OUT_OF_STOCK_PRODUCT,
    })
  }

  trackOutOfStockProduct() {
    this.$gtm.push({
      event: OUT_OF_STOCK_PRODUCT,
    })
  }

  trackCodeResend() {
    this.$gtm.push({
      event: RESEND_VERIFICATION_CODE,
    })
  }

  trackOpenCCL({ eventLocation }) {
    this.$gtm.push({
      event: CLICK_PREFERENCES,
      event_location: eventLocation,
    })
  }

  trackShowPaymentMethods(more) {
    this.$gtm.push({
      event: more ? SHOW_MORE_PAYMENT_METHODS : SHOW_LESS_PAYMENT_METHODS,
    })
  }

  trackEmptyCategoriesPage() {
    this.$gtm.push({
      event: CATEGORY_COMING_SOON,
    })
  }

  trackCheckoutCreditcardStartPayment() {
    this.$gtm.push({
      event: CHECKOUT_CREDITCARD_STARTPAYMENT,
    })
  }

  trackCheckoutAvsDetailsLoaded() {
    this.$gtm.push({
      event: CHECKOUT_AVS_DETAILS_LOADED,
    })
  }

  trackCheckoutMobileVerificationInputError(errorMessage) {
    this.$gtm.push({
      event: CHECKOUT_MOBILEVERIFICATION_INPUT_ERROR,
      error_type: 'form_submission',
      error_msg: errorMessage,
    })
  }

  trackCheckoutMobileVerificationResendCode() {
    this.$gtm.push({
      event: CHECKOUT_MOBILEVERIFICATION_RESEND_CODE,
    })
  }

  trackCheckoutMobileVerificationVerify() {
    this.$gtm.push({
      event: CHECKOUT_MOBILEVERIFICATION_VERIFY,
    })
  }

  trackCheckoutAvsDetailsStartpayment() {
    this.$gtm.push({
      event: CHECKOUT_AVS_DETAILS_STARTPAYMENT,
    })
  }

  trackCheckoutCreditCardDetailsLoaded() {
    this.$gtm.push({
      event: CHECKOUT_CREDITCARD_DETAILS_LOADED,
    })
  }

  trackCheckoutMobileVerificationLoaded() {
    this.$gtm.push({
      event: CHECKOUT_MOBILEVERIFICATION_LOADED,
    })
  }

  trackCheckoutPscDetailsContinue() {
    this.$gtm.push({
      event: CHECKOUT_PSC_DETAILS_CONTINUE,
    })
  }

  trackCheckoutPscDetailsLoaded() {
    this.$gtm.push({
      event: CHECKOUT_PSC_DETAILS_LOADED,
    })
  }

  trackCheckoutPscDetailsChange() {
    this.$gtm.push({
      event: CHECKOUT_PSC_DETAILS_CHANGE,
    })
  }

  trackCategoryBannerClick(eventName, selectedCategory, eventLocation) {
    this.$gtm.push({
      event: eventName,
      selected_category: selectedCategory,
      event_location: eventLocation,
    })
  }

  trackShowReorderBanner(eventLocation) {
    this.$gtm.push({
      event: SHOW_REORDER_BANNER,
      event_location: eventLocation,
    })
  }

  trackShowReorderButton(eventLocation) {
    this.$gtm.push({
      event: SHOW_REORDER_BUTTON,
      event_location: eventLocation,
    })
  }

  trackReorderChangeAmount() {
    this.$gtm.push({ event: REORDER_CHANGE_AMOUNT })
  }

  trackShowAllOrders() {
    this.$gtm.push({
      event: SHOW_ALL_ORDERS,
    })
  }

  confirmCCL({
    eventName,
    selectedProductCountry,
    selectedAppLanguage,
    selectedPurchaseCurrency,
    eventLocation,
  }) {
    const gtmObject = {
      event: eventName,
      event_location: eventLocation,
      ...(selectedProductCountry && {
        selected_product_country: selectedProductCountry,
      }),
      ...(selectedAppLanguage && {
        selected_app_language: selectedAppLanguage,
      }),
      ...(selectedPurchaseCurrency && {
        selected_purchase_currency: selectedPurchaseCurrency,
      }),
    }
    this.$gtm.push(gtmObject)
  }

  showSelector({ type, eventLocation }) {
    this.$gtm.push({
      event: `select_${type}`,
      event_location: eventLocation,
    })
  }

  confirmSelectType({ type, item, eventLocation }) {
    const selector = {
      country: 'selected_product_country',
      language: 'selected_app_language',
      currency: 'selected_purchase_currency',
    }
    const itemPropName = {
      country: 'code',
      language: 'language',
      currency: 'currency',
    }

    this.$gtm.push({
      event: `confirm_select_${type}`,
      event_location: eventLocation,
      [selector[type]]: item[itemPropName[type]],
    })
  }

  searchInput(type) {
    this.$gtm.push({
      event: SEARCH,
      search_topic: type,
    })
  }

  viewSearchResults(searchTerm, type) {
    if (!searchTerm) return
    this.$gtm.push({
      event: VIEW_SEARCH_RESULTS,
      search_topic: type,
      search_term: searchTerm,
    })
  }

  trackSelectProductCurrency({ eventLocation }) {
    this.$gtm.push({
      event: GTMEvents.SELECT_PRODUCT_CURRENCY,
      event_location: eventLocation,
    })
  }

  trackConfirmSelectProductCurrency({
    eventLocation,
    selectedProductCurrency,
  }) {
    this.$gtm.push({
      event: GTMEvents.CONFIRM_SELECT_PRODUCT_CURRENCY,
      event_location: eventLocation,
      selected_product_currency: selectedProductCurrency,
    })
  }

  trackPrimerCheckoutWidgetCompletepayment() {
    this.$gtm.push({
      event: GTMEvents.CHECKOUT_WIDGET_COMPLETEPAYMENT,
    })
  }

  trackPrimerCheckoutWidgetLoaded() {
    this.$gtm.push({
      event: GTMEvents.CHECKOUT_WIDGET_LOADED,
    })
  }

  trackPrimerCheckoutCreateClientSession() {
    this.$gtm.push({
      event: GTMEvents.CHECKOUT_CREATE_CLIENT_SESSION,
    })
  }

  trackPrimerCheckoutWidgetStartpayment() {
    this.$gtm.push({
      event: GTMEvents.CHECKOUT_WIDGET_STARTPAYMENT,
    })
  }

  trackPrimerCheckoutWidgetSelectpayment(paymentMethodType) {
    this.$gtm.push({
      event: GTMEvents.CHECKOUT_WIDGET_SELECTPAYMENT,
      selected_payment_method: paymentMethodType,
    })
  }

  trackPrimerCheckoutWidgetPaymentError(status) {
    this.$gtm.push({
      event: GTMEvents.CHECKOUT_WIDGET_PAYMENT_ERROR,
      payment_error_message: status,
    })
  }

  trackReorderClick(eventLocation) {
    this.$gtm.push({
      event: REORDER,
      event_location: eventLocation,
    })
  }

  trackClickRafPromotionalTerms() {
    this.$gtm.push({
      event: CLICK_RAF_PROMOTIONAL_TERMS,
    })
  }

  trackClickJoinByRaf() {
    this.$gtm.push({
      event: CLICK_JOIN_BY_RAF,
    })
  }

  trackShowWebToAppBanner() {
    this.$gtm.push({
      event: SHOW_WEB_TO_APP_BANNER,
    })
  }

  trackWebToAppDownloadApp() {
    this.$gtm.push({
      event: WEB_TO_APP_DOWNLOAD_APP,
    })
  }

  trackWebToAppContinueNavigating() {
    this.$gtm.push({
      event: WEB_TO_APP_CONTINUE_NAVIGATING,
    })
  }
}

const gtm: Plugin = ({ $gtm, route, store }, inject): void => {
  const gtmEnhanced = new Analitycs({ $gtm, route, store })
  inject('gtmEnhanced', gtmEnhanced)
}

export default gtm
